"use client";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { motion, useScroll } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const { scrollY } = useScroll();
  const [fixed, setFixed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const heroSection = document.getElementById("hero");
    const heroHeight = heroSection ? heroSection.offsetHeight : 0;

    const unsubscribe = scrollY.onChange((current) => {
      setFixed(current >= heroHeight);
    });

    return () => unsubscribe();
  }, [scrollY]);

  const handleLogoClick = useCallback(() => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
    }
  }, [location.pathname, navigate]);

  const handleJoinClick = useCallback(() => {
    navigate("/join-waitlist");
  }, [navigate]);

  const buttonDisplay = useMemo(() => location.pathname !== "/join-waitlist", [location.pathname]);

  return (
    <motion.header
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      className={`${
        fixed
          ? "fixed bg-black top-0 left-0 w-full z-50 py-4"
          : "absolute bg-transparent top-0 left-0 w-full z-20 py-4"
      } transition-all duration-200`}
    >
      <div className="container mx-auto flex justify-between items-center px-4 sm:px-0">
        <h1
          className="text-3xl font-bold text-white drop-shadow-md cursor-pointer"
          onClick={handleLogoClick}
        >
          AuTodo
        </h1>
        {buttonDisplay && (
          <button
            className="bg-[#4B0082] text-white py-2 px-4 rounded-6px] drop-shadow-md hover:bg-[#800080] active:bg-[#4B0082] transition-colors duration-300" // Adjusted for rounder corners
            onClick={handleJoinClick}
          >
            Join the Waitlist
          </button>
        )}
      </div>
    </motion.header>
  );
};

export default React.memo(Header);
