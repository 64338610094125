import React, { useMemo } from 'react';
import { FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa';

const socialLinks = [
  { href: "https://linkedin.com", icon: FaLinkedin, label: "LinkedIn" },
  { href: "https://instagram.com", icon: FaInstagram, label: "Instagram" },
  { href: "https://twitter.com", icon: FaTwitter, label: "Twitter" },
];

const navLinks = [
  { href: "#about", label: "About" },
  { href: "#services", label: "Services" },
  { href: "#contact", label: "Contact" },
];

const Footer: React.FC = () => {
  const renderedSocialLinks = useMemo(() => socialLinks.map(({ href, icon: Icon, label }) => (
    <a
      key={label}
      href={href}
      aria-label={label}
      className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition"
    >
      <Icon className="h-5 w-5" />
    </a>
  )), []);

  const renderedNavLinks = useMemo(() => navLinks.map(({ href, label }) => (
    <a
      key={label}
      href={href}
      className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition"
    >
      {label}
    </a>
  )), []);

  return (
    <footer className="bg-black dark:bg-black border-t border-gray-200 dark:border-gray-700 py-16">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
          <div className="flex space-x-6">
            <span className="text-xl font-bold text-gray-900 dark:text-white">AuTodo</span>
          </div>
          <div className="flex space-x-4 ml-4">
            {renderedSocialLinks}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start mt-4">
          <div className="flex space-x-6">
            {renderedNavLinks}
          </div>
          <p className="text-gray-600 dark:text-gray-300 md:mt-0">© 2024 AuTodo. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
