import React, { useMemo } from 'react';
import { BentoCard, BentoGrid } from "./magicui/bento-grid";
import highlightsData from '../data/highlightsData';
import { CalendarIcon, InputIcon, DashboardIcon, PersonIcon, MagicWandIcon } from "@radix-ui/react-icons";

const Highlights: React.FC = () => {
  const memoizedIconMap = useMemo(() => ({
    CalendarIcon: CalendarIcon,
    InputIcon: InputIcon,
    DashboardIcon: DashboardIcon,
    PersonIcon: PersonIcon,
    MagicWandIcon: MagicWandIcon,
  }), []);

  return (
    <div className="flex flex-col items-center bg-gray-100 dark:bg-black text-gray-900 dark:text-white pt-40 pb-12 px-4 md:pt-42 md:pb-36 md:px-0">
      <div className="md:w-11/12 w-full max-w-screen-xl">
        <div className="flex flex-col md:flex-row justify-between items-start mb-16">
          <h2 className="text-3xl md:text-5xl font-bold">Get the Highlights</h2>
        </div>
        <div className="relative h-auto w-full">
          <BentoGrid className="lg:grid-rows-3">
            {highlightsData.map((highlight) => {
              const Icon = memoizedIconMap[highlight.iconName as keyof typeof memoizedIconMap];
              return (
                <BentoCard
                  key={highlight.name}
                  Icon={Icon}
                  name={highlight.name}
                  description={highlight.description}
                  href={highlight.href}
                  cta={highlight.cta}
                  background={<img src={highlight.backgroundSrc} alt={highlight.name} className="absolute -right-20 -top-20 opacity-60" />}
                  className={highlight.className}
                />
              );
            })}
          </BentoGrid>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Highlights);
