import { AdjustmentsHorizontalIcon, BellIcon, Cog6ToothIcon, Squares2X2Icon, ChartPieIcon, FlagIcon, FolderIcon, LinkIcon, TableCellsIcon } from '@heroicons/react/20/solid';

export const featuresData = [
  {
    "heading": "Smart Scheduling",
    "subheading": "Optimize Your Time",
    "description": "Automatically organize and prioritize your tasks based on your preferences and past performance, ensuring optimal productivity.",
    "textOnLeft": true,
    "data": [
      {
        "name": "Dynamic Task Prioritization",
        "description": "Automatically adjusts task priorities based on real-time changes in your schedule and workload.",
        "icon": AdjustmentsHorizontalIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      },
      {
        "name": "Real-Time Updates",
        "description": "Get instant updates and notifications as your schedule adapts to your progress and changes.",
        "icon": BellIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      },
      {
        "name": "Optimized Workflows",
        "description": "Experience a streamlined workflow with tasks organized for maximum efficiency and minimal stress.",
        "icon": Cog6ToothIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      }
    ]
  },
  {
    "heading": "Task Breakdown",
    "subheading": "Simplify Your Tasks",
    "description": "Break down complex tasks into manageable sub-tasks, making it easier to tackle large projects step by step.",
    "textOnLeft": false,
    "data": [
      {
        "name": "Detailed Task Decomposition",
        "description": "Divide large tasks into smaller, actionable steps to keep projects on track.",
        "icon": Squares2X2Icon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      },
      {
        "name": "Progress Monitoring",
        "description": "Track your progress with detailed insights and completion percentages.",
        "icon": ChartPieIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      },
      {
        "name": "Milestone Management",
        "description": "Set and achieve milestones to stay motivated and organized.",
        "icon": FlagIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      }
    ]
  },
  {
    "heading": "Task Grouping",
    "subheading": "Organize Efficiently",
    "description": "Easily group related tasks together to create a 'Father Task,' streamlining your workflow and improving organization.",
    "textOnLeft": true,
    "data": [
      {
        "name": "Hierarchical Task Structure",
        "description": "Organize tasks in a parent-child structure for better project management.",
        "icon": FolderIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      },
      {
        "name": "Task Dependencies",
        "description": "Define dependencies to ensure tasks are completed in the correct order.",
        "icon": LinkIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      },
      {
        "name": "Comprehensive Overviews",
        "description": "Get a unified view of all grouped tasks to manage projects effectively.",
        "icon": TableCellsIcon,
        "image": "https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
      }
    ]
  }
];
