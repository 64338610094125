interface HighlightData {
  iconName: 'CalendarIcon' | 'InputIcon' | 'DashboardIcon' | 'PersonIcon' | 'MagicWandIcon';
  name: string;
  description: string;
  href: string;
  cta: string;
  backgroundSrc: string;
  className: string;
}

const highlightsData: HighlightData[] = [
  {
    iconName: "CalendarIcon",
    name: "Smart Scheduling",
    description: "Smartly plan your things based on your preferences and daily habits.",
    href: "/",
    cta: "Learn more",
    backgroundSrc: "./path/to/image2.webp",
    className: "lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-3",
  },
  {
    iconName: "InputIcon",
    name: "Task Breakdown",
    description: "Helping you break down big tasks into smaller, easy-to-do parts.",
    href: "/",
    cta: "Learn more",
    backgroundSrc: "./path/to/image1.webp",
    className: "lg:row-start-1 lg:row-end-4 lg:col-start-2 lg:col-end-3",
  },
  {
    iconName: "MagicWandIcon",
    name: "Thing",
    description: "Unique unit that can be a task, reminder, etc., which AuTodo determines dynamically.",
    href: "/",
    cta: "Learn more",
    backgroundSrc: "./path/to/image3.webp",
    className: "lg:col-start-1 lg:col-end-2 lg:row-start-3 lg:row-end-4",
  },
  {
    iconName: "DashboardIcon",
    name: "Things Grouping",
    description: "Grouping related Things together, making it easier to manage and track them.",
    href: "/",
    cta: "Learn more",
    backgroundSrc: "./path/to/image4.webp",
    className: "lg:col-start-3 lg:col-end-3 lg:row-start-1 lg:row-end-2",
  },
  {
    iconName: "PersonIcon",
    name: "Team Allocation",
    description: "Assigning tasks to team members based on their strengths and current workload.",
    href: "/",
    cta: "Learn more",
    backgroundSrc: "./path/to/image5.webp",
    className: "lg:col-start-3 lg:col-end-3 lg:row-start-2 lg:row-end-4",
  },
];

export default highlightsData;
