import React, { useEffect, useMemo, useCallback } from "react";
import { Canvas } from "@react-three/fiber";
import { useMotionTemplate, useMotionValue, motion, animate } from "framer-motion";
import { useNavigate } from "react-router-dom";
import AnimatedGradientText from "./magicui/animated-gradient-text";
import { cn } from "./magicui/lib/utils";
import { BorderBeam } from "./magicui/border-beam";
import { FlipWords } from "./utils/FlipWords";
import { BackgroundBeams } from "./utils/background-beams";

const LazyStars = React.lazy(() =>
  import("@react-three/drei").then(module => ({ default: module.Stars }))
);

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];
const words = ["Intelligent", "Beautiful", "Efficient", "Powerful"];

const Hero: React.FC = React.memo(() => {
  const color = useMotionValue(COLORS_TOP[0]);
  const gradientHeight = useMotionValue(32);
  const navigate = useNavigate();

  useEffect(() => {
    const colorControls = animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 4,
      repeat: Infinity,
      repeatType: "mirror",
    });

    const gradientHeightControls = animate(gradientHeight, [88, 100, 88], {
      ease: "easeInOut",
      duration: 3,
      repeat: Infinity,
      repeatType: "mirror",
    });

    return () => {
      colorControls.stop();
      gradientHeightControls.stop();
    };
  }, [color, gradientHeight]);

  const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
  const border = useMotionTemplate`1px solid ${color}`;
  const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;
  const gradientHeightStyle = useMotionTemplate`${gradientHeight}px`;

  const handleJoinClick = useCallback(() => {
    navigate("/join-waitlist");
  }, [navigate]);

  const heroStyle = useMemo(() => ({
    backgroundImage,
  }), [backgroundImage]);

  const buttonStyle = useMemo(() => ({
    border,
    boxShadow,
    backgroundColor: "#4B0082",
    borderRadius: "6px", // More rounded corners
  }), [border, boxShadow]);

  const gradientStyle = useMemo(() => ({
    height: gradientHeightStyle,
  }), [gradientHeightStyle]);

  return (
    <motion.section
      id="hero"
      style={heroStyle}
      className="relative grid min-h-screen place-content-center overflow-hidden bg-gray-950 px-4 pt-24 pb-10 text-gray-200"
    >
      <div className="absolute inset-0 z-0">
        <React.Suspense fallback={null}>
          <Canvas>
            <LazyStars radius={50} count={2500} factor={4} fade speed={2} />
          </Canvas>
        </React.Suspense>
      </div>
      <div className="relative z-10 flex flex-col items-center bg-transparent text-gray-900 dark:text-white pt-32 pb-12 px-4 md:pt-48 md:36 md:px-0">
        <section className="text-center mb-12 px-4 md:px-0">
          <div className="container mx-auto max-w-screen-xl px-4 text-center">
            <AnimatedGradientText>
              🎉 <hr className="mx-2 h-4 w-[1px] shrink-0 bg-gray-300" />{" "}
              <span
                className={cn(
                  `inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
                )}
              >
                Introducing AuTodo
              </span>
            </AnimatedGradientText>
            <h2 className="text-3xl md:text-5xl font-bold mb-6 mt-16 text-white">
              The World's Most <span className="inline-block w-1/1.5 sm:w-1/2.5 md:w-1/3 lg:w-1/4 xl:w-1/5"><FlipWords words={words} /></span> Task Manager
            </h2>
            <p className="text-lg md:text-2xl mb-8 md:mb-16 text-white">
              Seamlessly manage your tasks, stay effortlessly organized, and focus on what truly matters.
            </p>
            <motion.button
              style={buttonStyle}
              whileHover={{ scale: 1.015 }}
              whileTap={{ scale: 0.985 }}
              className="text-white py-2 px-6 text-lg mb-8 group"
              onClick={handleJoinClick}
            >
              Join the Waitlist
              <span className="inline-block ml-2 transition-transform duration-300 ease-in-out group-hover:translate-x-1">
                →
              </span>
            </motion.button>
          </div>
          <div className="w-full flex justify-center mt-12 md:mt-24 lg:mt-36">
            <div className="relative h-auto w-full max-w-screen-xl">
              <BorderBeam />
              <img src="/heroBlack.webp" alt="Hero" className="w-full h-auto rounded-xl" />
            </div>
          </div>
        </section>
      </div>
      <motion.div
        style={gradientStyle}
        className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent z-20"
      />
      <BackgroundBeams />
    </motion.section>
  );
});

export default Hero;
