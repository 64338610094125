import React from "react";
import Highlights from "../components/Highlights";
import FeaturesSection from "../components/FeaturesSections";
import Footer from "../components/Footer";
import Thing from "../components/Thing";
import Hero from "../components/Hero";
import BottomSentence from "../components/BottomSentence";

const LandingPage: React.FC = () => {
    return (
        <div className="dark bg-black dark:bg-black text-gray-900 dark:text-white font-inter antialiased flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
            <Hero />
            <Highlights />
            <FeaturesSection />
            <Thing />
            <BottomSentence />
            <Footer />
        </div>
    );
};

export default React.memo(LandingPage);
