import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Header from "./components/Header";

const JoinPage = React.lazy(() => import("./pages/JoinPage"));

const App: React.FC = () => {
  useEffect(() => {
    import("./pages/JoinPage");
    import("./components/utils/ParticleRing");
  }, []);

  return (
    <Router>
      <div className="dark bg-black dark:bg-black text-gray-900 dark:text-white font-inter antialiased flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/join-waitlist"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <JoinPage />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
