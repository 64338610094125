import React from 'react';
import Feature from './Feature';
import { featuresData } from '../data/featuresData';

const FeaturesSection = () => {
  try {
    return (
      <>
        {featuresData.map((featureSet, index) => (
          <Feature 
            key={index}
            heading={featureSet.heading}
            subheading={featureSet.subheading}
            description={featureSet.description}
            data={featureSet.data}
            textOnLeft={featureSet.textOnLeft}
          />
        ))}
      </>
    );
  } catch (error) {
    console.error('Error rendering FeaturesSection:', error);
    return <div>Error rendering features. Please check the console for more details.</div>;
  }
};

export default FeaturesSection;
