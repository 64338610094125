export interface ThingCardData {
  title: string;
  description: string;
  gradient: string;
}

export const thingCardData: ThingCardData[] = [
  {
    "title": "Task",
    "description": "Specific action item to complete, helping you stay organized and productive.",
    "gradient": "bg-[radial-gradient(var(--mask-size)_circle_at_var(--mouse-x)_var(--mouse-y),#ffaa40_0,#9c40ff_50%,transparent_100%)]"
  },
  {
    "title": "Reminder",
    "description": "Serves as a nudge to remember important events or actions, ensuring you never miss a crucial moment.",
    "gradient": "bg-[radial-gradient(var(--mask-size)_circle_at_var(--mouse-x)_var(--mouse-y),#ffaa40_0,#9c40ff_50%,transparent_100%)]"
  },
  {
    "title": "Command",
    "description": "Lets you issue directives or instructions, streamlining your workflow and enhancing productivity.",
    "gradient": "bg-[radial-gradient(var(--mask-size)_circle_at_var(--mouse-x)_var(--mouse-y),#ffaa40_0,#9c40ff_50%,transparent_100%)]"
  }
];
