"use client";
import React, { useMemo } from 'react';
import { motion } from "framer-motion";
import { HeroHighlight, Highlight } from "./utils/hero-highlight";

const BottomSentence: React.FC = React.memo(() => {
  const initialAnimation = useMemo(() => ({
    opacity: 0,
    y: 20,
  }), []);

  const animateProps = useMemo(() => ({
    opacity: 1,
    y: [20, -5, 0],
  }), []);

  const transitionProps = useMemo(() => ({
    duration: 0.5,
    ease: [0.4, 0.0, 0.2, 1],
  }), []);

  return (
    <HeroHighlight>
      <motion.h1
        initial={initialAnimation}
        animate={animateProps}
        transition={transitionProps}
        className="text-2xl px-4 md:text-4xl lg:text-5xl font-bold text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-snug text-center mx-auto"
      >
        With AuTodo, nothing&apos;s overwhelming. Everything is under control. Everything
        is{" "}
        <Highlight className="text-black dark:text-white">
          divided, scheduled, and done.
        </Highlight>
      </motion.h1>
    </HeroHighlight>
  );
});

export default BottomSentence;
