import React, { useMemo } from "react";
import { MagicCard, MagicContainer } from "./magicui/magic-card";
import { thingCardData } from "../data/thingData";

const Thing: React.FC = () => {
  const memoizedThingCardData = useMemo(() => thingCardData, []);

  return (
    <div className="flex flex-col items-center bg-black dark:bg-black text-gray-900 dark:text-white pt-8 pb-52">
      <div className="mx-4 w-full max-w-7xl mx-auto px-2">
        <div className="flex flex-col md:flex-row justify-between items-start mb-16">
          <div className="w-full p-2 xl:p-0">
            <h1 className="text-4xl lg:text-5xl font-bold">Introducing Thing</h1>
            <p className="text-lg lg:text-xl mt-8">
              Boost your productivity with Thing, seamlessly managing tasks, reminders, and commands in one place to streamline your workflow and accomplish more with ease.
            </p>
          </div>
        </div>
        <MagicContainer
          className="mx-auto flex h-[600px] md:h-[500px] w-full flex-col gap-6 lg:flex-row"
        >
          {memoizedThingCardData.map((card, index) => (
            <MagicCard
              key={index}
              borderWidth={3}
              className={`relative flex w-full cursor-pointer flex-col items-center justify-start overflow-hidden ${card.gradient} p-2 lg:pt-36 lg:px-8 shadow-2xl`}
            >
              <p className="text-3xl lg:text-4xl font-medium text-gray-800 dark:text-gray-200 mt-8">
                {card.title}
              </p>
              <p className="text-base lg:text-lg text-gray-600 dark:text-gray-400 mt-4 break-words text-center">
                {card.description}
              </p>
              <div className="pointer-events-none absolute inset-0 h-full bg-[radial-gradient(circle_at_50%_120%,rgba(120,119,198,0.3),rgba(255,255,255,0))]" />
            </MagicCard>
          ))}
        </MagicContainer>
      </div>
    </div>
  );
};

export default React.memo(Thing);
