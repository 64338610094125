import React, { FC, useMemo, useState, useEffect } from 'react';

interface FeatureProps {
  heading: string;
  subheading: string;
  description: string;
  data: {
    name: string;
    description: string;
    icon: React.ElementType;
    image: string;
  }[];
  textOnLeft: boolean;
}

const Feature: FC<FeatureProps> = ({ heading, subheading, description, data, textOnLeft }) => {
  const renderedFeatures = useMemo(() => data.map((feature, index) => (
    <div key={index} className="relative pl-9">
      <dt className="inline font-semibold text-white">
        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-400" aria-hidden="true" />
        {feature.name}
      </dt>{' '}
      <dd className="inline">{feature.description}</dd>
    </div>
  )), [data]);

  const [isLargeScreen, setIsLargeScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth > 1024 : true
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="overflow-hidden bg-black py-24 sm:py-32">
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        {isLargeScreen ? (
          <div className={`relative grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:max-w-none lg:grid-cols-2 ${textOnLeft ? '' : 'lg:flex-row-reverse'}`}>
            {textOnLeft ? (
              <>
                <div className="lg:pr-8 lg:pt-4">
                  <div className="lg:max-w-lg">
                    <h2 className="text-base font-semibold leading-7 text-indigo-400">{heading}</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{subheading}</p>
                    <p className="mt-6 text-lg leading-8 text-gray-400">{description}</p>
                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
                      {renderedFeatures}
                    </dl>
                  </div>
                </div>
                <img
                  src={data[0].image}
                  alt="Product screenshot"
                  className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                  width={2432}
                  height={1442}
                />
              </>
            ) : (
              <>
                <div className="absolute inset-y-0 left-0 w-full lg:w-auto lg:relative lg:-left-52">
                  <img
                    src={data[0].image}
                    alt="Product screenshot"
                    className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] lg:-ml-32"
                    width={2432}
                    height={1442}
                  />
                </div>
                <div className="lg:pl-20 lg:pt-4">
                  <div className="lg:max-w-2lg">
                    <h2 className="text-base font-semibold leading-7 text-indigo-400">{heading}</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{subheading}</p>
                    <p className="mt-6 text-lg leading-8 text-gray-400">{description}</p>
                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
                      {renderedFeatures}
                    </dl>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={`relative grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:max-w-none lg:grid-cols-2`}>
            <div className={`pr-8 pt-4`}>
              <div>
                <h2 className="text-base font-semibold leading-7 text-indigo-400">{heading}</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{subheading}</p>
                <p className="mt-6 text-lg leading-8 text-gray-400">{description}</p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
                  {renderedFeatures}
                </dl>
              </div>
            </div>
            <img
              src={data[0].image}
              alt="Product screenshot"
              className={`pr-8 pt-4 w-[36rem] sm:w-[48rem] md:w-[56rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0`}
              width={2432}
              height={1442}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Feature);
